<template>
  <div>
    <div class="search-wrapper panel-heading col-sm-12">
      <b-form-group>
        <label for="search">Firma Arama:</label>
        <b-form-input
          id="search"
          v-model="searchTerm"
          placeholder="Firma Ara"
          type="text"
        />
      </b-form-group>
    </div>
    <div
      v-if="deger"
      class="d-flex justify-content-center mt-4 mb-1"
    >
      <h3 class="mr-2">
        Yönlendirilecek talepler yükleniyor, Lütfen bekleyiniz..
      </h3>
      <b-spinner
        variant="success"
      />
    </div>
    <div
      v-for="r in filteredFirm"
      :key="r.firmId"
      class="row mb-2 border row "
    >
      <div class="col-lg-2 col-md-2 d-grid text-center">
        <center>
          <br><br>
          <img
            :src="r.staffRequestSummaryViewModel.firmInfo.firmLogo === '' ? require('@/assets/images/firmnoImage.png') : r.staffRequestSummaryViewModel.firmInfo.firmLogo"
            class="img-fluid"
            width="100"
            heigt="100"
          >
          <br><br>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            size="sm"
            squared
            class="mr-1 mb-1"
            variant="primary"
            @click="onGetFirmClicked(r)"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-30"
            />
            <span>Firma Envanter</span>
          </b-button>
          <br><br>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            size="sm"
            squared
            class="mr-1 mb-1"
            variant="danger"
            @click="onDuzenleBtnClicked(r)"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-30"
            />
            <span>Talep Detay</span>
          </b-button>
        </center>
      </div>
      <div class="col-lg-4 col-md-4 row align-items-center mt-1">
        <table class="table table-bordered table-sm">
          <tbody>
            <tr>
              <th class="text-danger">
                Firma Unvanı:
              </th>
              <td class="text-center">
                {{ r.staffRequestSummaryViewModel.firmInfo.firmTitle }}
              </td>
            </tr>
            <tr>
              <th class="text-danger">
                Pozisyon İsmi:
              </th>
              <td class="text-center">
                {{ r.staffRequestSummaryViewModel.position }}
              </td>
            </tr>
            <tr>
              <th class="text-danger">
                Personel Sınıfı:
              </th>
              <td class="text-center">
                {{ r.staffRequestSummaryViewModel.staffClass }}
              </td>
            </tr>
            <tr>
              <th class="text-danger">
                Alınacak Kişi Sayısı:
              </th>
              <td class="text-center">
                {{ r.staffRequestSummaryViewModel.personelCount }}
              </td>
            </tr>
            <tr>
              <th
                colspan="2"
              >
                &nbsp;
              </th>
            </tr>
            <tr>
              <th
                colspan="2"
              >
                TALEP SKORU
              </th>
            </tr>
            <tr>
              <th
                class="text-danger"
              >
                Kişisel Özellik:
              </th>
              <td
                class="text-center"
              >
                {{ r.evaluationStaffRequestSummaryViewModel.requestPersonalScore.toFixed(2) }}
              </td>
            </tr>
            <tr>
              <th class="text-danger">
                Mesleki Yeterlilik:
              </th>
              <td class="text-center">
                {{ r.evaluationStaffRequestSummaryViewModel.requestVocationalCompilanceScore.toFixed(2) }}
              </td>
            </tr>
            <tr>
              <th class="text-danger">
                Temel Yetenek:
              </th>
              <td class="text-center">
                {{ r.evaluationStaffRequestSummaryViewModel.requestBasicAbilityScore.toFixed(2) }}
              </td>
            </tr>
            <tr>
              <th class="text-danger">
                Teknik Yeterlilik:
              </th>
              <td class="text-center">
                {{ r.evaluationStaffRequestSummaryViewModel.requestTechnicalCompilanceScore.toFixed(2) }}
              </td>
            </tr>
            <tr>
              <th class="text-danger">
                Risk:
              </th>
              <td class="text-center">
                {{ r.evaluationStaffRequestSummaryViewModel.requestRiskScore.toFixed(2) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-lg-4 col-md-4 row mt-1">
        <table class="table table-bordered table-sm">
          <tbody>
            <tr>
              <th class="text-danger">
                Talep Tarihi:
              </th>
              <td class="text-center">
                {{ r.staffRequestSummaryViewModel.requestOpenDate.substring(0,10) }}
              </td>
            </tr>
            <tr>
              <th class="text-danger">
                Faktör:
              </th>
              <td class="text-center">
                {{ r.staffRequestSummaryViewModel.factorPosition.staffPositionName }}
              </td>
            </tr>
            <tr>
              <th class="text-danger">
                Çalışma Şekli:
              </th>
              <td class="text-center">
                {{ r.staffRequestSummaryViewModel.jobType }}
              </td>
            </tr>
            <tr>
              <th class="text-danger">
                Görev Açıklaması:
              </th>
              <td class="text-center">
                {{ r.staffRequestSummaryViewModel.shortPositionDescription }}
              </td>
            </tr>
            <tr>
              <th
                colspan="2"
              >
                &nbsp;
              </th>
            </tr>
            <tr>
              <th
                colspan="2"
              >
                EŞLEŞEN ADAYLAR ORTALAMA SKORLARI
              </th>
            </tr>
            <tr>
              <th class="text-danger">
                Kişisel Özellik:
              </th>
              <td class="text-center">
                {{ r.evaluationStaffRequestSummaryViewModel.totalAvgPersonalScore.toFixed(2) }}
              </td>
            </tr>
            <tr>
              <th class="text-danger">
                Mesleki Yeterlilik:
              </th>
              <td class="text-center">
                {{ r.evaluationStaffRequestSummaryViewModel.totalAvgVocationalCompilanceScore.toFixed(2) }}
              </td>
            </tr>
            <tr>
              <th class="text-danger">
                Temel Yetenek:
              </th>
              <td class="text-center">
                {{ r.evaluationStaffRequestSummaryViewModel.totalAvgBasicAbilityScore.toFixed(2) }}
              </td>
            </tr>
            <tr>
              <th class="text-danger">
                Teknik Yeterlilik:
              </th>
              <td class="text-center">
                {{ r.evaluationStaffRequestSummaryViewModel.totalAvgTechnicalCompilanceScore.toFixed(2) }}
              </td>
            </tr>
            <tr>
              <th class="text-danger">
                Risk:
              </th>
              <td class="text-center">
                {{ r.evaluationStaffRequestSummaryViewModel.totalAvgRiskScore.toFixed(2) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-lg-2 col-md-12 ml-1 row align-items-center text-center">
        <center>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            squared
            block
            class="mr-1 mb-1"
            variant="success"
            @click="onYonlendirBtnClicked(r)"
          >
            <feather-icon
              icon="EyeIcon"
              class="mr-3"
            />
            <span> Yönlendirme & Takip</span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            squared
            block
            class="mr-1 mb-1"
            variant="danger"
            @click="onKapatBtnClicked(r)"
          >
            <feather-icon
              icon="XIcon"
              class="mr-3"
            />
            <span> Talebi kapat</span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            squared
            block
            class="mr-1 mb-1"
            variant="secondary"
            @click="onPasifBtnClicked(r)"
          >
            <feather-icon
              icon="ShieldOffIcon"
              class="mr-3"
            />
            <span> Talebi pasif et</span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            squared
            block
            class="mr-1 mb-1"
            variant="primary"
            @click="onEslestirBtnClicked(r)"
          >
            <feather-icon
              icon="LinkIcon"
              class="mr-2"
            />
            <span> Talebi yeniden eşleştir</span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            squared
            block
            class="mr-1 mb-1"
            variant="warning"
            @click="onMesajBtnClicked(r)"
          >
            <feather-icon
              icon="MessageSquareIcon"
              class="mr-3"
            />
            <span>İşverene mesaj</span>
          </b-button>
        </center>
      </div>
    </div>
  </div>
</template>
<script>
import {
  BButton, VBModal, BFormGroup, BFormInput, BSpinner, // BRow, BCol, BBadge, BForm, BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import * as apiTalep from '@/api/islemler/firmRequest'
import * as apiEval from '@/api/islemler/Evaluation'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
// import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  components: {
    BButton,
    BFormGroup,
    BFormInput,
    BSpinner,
  /* AppCollapse,
    AppCollapseItem,
    BRow,
    BCol,
    BBadge,
    BForm,
    BFormTextarea,
    BFormGroup,
  */
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  data() {
    return {
      evalutationStaffingId: 0,
      applicantId: 0,
      redirectionNote: '',
      pageLength: 15,
      list: [],
      rows: [],
      rws: [],
      searchTerm: '',
      deger: true,
      requestScores: [],
      rowsall: [],
    }
  },
  computed: {
    filteredFirm() {
      return this.rows.filter(p => p.staffRequestSummaryViewModel.firmInfo.firmTitle.toLowerCase().indexOf(this.searchTerm.toLowerCase()) !== -1)
    },
  },
  created() {
    this.getSRequests()
  },
  mounted() {

  },
  methods: {
    getSRequests() {
      apiEval.EvaluationStaffRequestSummaryHolderViewModel(1).then(res => {
        this.rws = res.data.list
        this.rows = this.rws.filter(x => x.evaluationOpenPositionViewModels !== null)
        this.deger = false
      })
    },
    onDataGridItemClicked(params) {
      return params
    },
    onYonlendirBtnClicked(row) {
      this.$router.push({ name: 'talep-yonlendirme', params: { requestId: row.staffRequestSummaryViewModel.staffRequestId, r: row } })
    },
    /*
     * 0 Aktif Eşleştirilmemiş
     * 1 Aktif Eşleştirilmiş
     * -1 Pasif
     * 2 Kapatılmış
    */
    onKapatBtnClicked(row) {
      let talep = null
      apiTalep.GetStaffRequestById(row.staffRequestSummaryViewModel.staffRequestId).then(res => {
        talep = res.data.entity
        talep.requestStatus = 2
        apiTalep.UpdateStaffRequest(talep).then(() => {
          this.$router.go(0)
        })
      })
    },
    onPasifBtnClicked(row) {
      let talep = null
      apiTalep.GetStaffRequestById(row.staffRequestSummaryViewModel.staffRequestId).then(res => {
        talep = res.data.entity
        talep.requestStatus = -1
        apiTalep.UpdateStaffRequest(talep).then(() => {
          // this.$router.go(0)
          this.getSRequests()
        })
      })
    },
    onEslestirBtnClicked(row) {
      apiEval.EvaluateStaffRequest(row.staffRequestSummaryViewModel.staffRequestId).then(resp => {
        if (resp.data.count > 0) {
          apiTalep.GetStaffRequestById(row.staffRequestSummaryViewModel.staffRequestId).then(tlp => {
            const talep = tlp.data.entity
            talep.requestStatus = 1
            apiTalep.UpdateStaffRequest(talep)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Talep yeniden eşleştirildi',
                text: 'Talep eşleştirmesi tamamlandı',
                icon: 'BellIcon',
                variant: 'success',
              },
            })
          })
        }
        // this.$router.go(0)
      })
    },

    onDuzenleBtnClicked(row) {
      this.$router.push({ name: 'yeni-talep', params: { firmStaffRequestId: row.staffRequestSummaryViewModel.staffRequestId, firmInfo: row.staffRequestSummaryViewModel.firmInfo } })
    },
    onGetFirmClicked(row) {
      this.$router.push({ name: 'firma-kart', params: { firmId: row.staffRequestSummaryViewModel.firmInfo.firmInfoId } })
    },
    onAdayBtnClicked(row) {
      this.$router.push({ name: 'aday-kayit', params: { applicantId: row.applicant.applicantId } })
    },
    strToDate(st) {
      let yil = ''
      yil = st.substring(0, 4)
      let ay = ''
      ay = st.substring(5, 7)
      let gun = ''
      gun = st.substring(8, 10)
      return `${gun}.${ay}.${yil}`
    },
    oran(a, b) {
      const yuzde = (a / b) * 100
      return yuzde.toFixed(2)
    },
    onModalCancelled() {},
    onModalSaveNote() {},
  },
}
</script>
<style lang='scss'>
   @import '@core/scss/vue/libs/vue-good-table.scss';
</style>
